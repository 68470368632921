const generalProps = {
    pageRefreshInterval: 60 // in mins
}

const devProps = {
    // url: "http://localhost:8081",
    url: "http://192.168.0.104:8081",   
     // apiUrl: "http://localhost:8080/api",
    apiUrl: "http://192.168.0.104:8080/api",
    userRefreshInterval: 60 * 5, // in sec
    enableReports: true,
}

const serverProps = {
    url: "https://myjw.app",
    apiUrl: "https://api.shro.cz/api",
    cookieDomain: '.shro.cz',
    userRefreshInterval: 60 * 2, // in sec
    enableReports: true,
}

const devActive = process.env.NODE_ENV === 'development'
const envProps = devActive ? devProps : serverProps

export default {
    ...generalProps,
    ...envProps,
    devActive: devActive,
}