const common = {
    contactAdmin: "Pravděpodobně se jedná o chybu v aplikaci. Kontaktujte administrátora.",
}

/*
 * https://stackoverflow.com/questions/12484386/access-javascript-property-case-insensitively
 */
function getValue(myObject, keyAnyCase) {
    return myObject[
        Object.keys(myObject)
            .find(key => key.toLowerCase() === keyAnyCase.toLowerCase())
    ]
}

export default {
    get(path, params) {
        //console.log(`[msg.get] path: ${path}, params: `, params)

        let msg = ""

        try {
            msg = path.split('.').reduce((o, i) => getValue(o, i), this)
        } catch (e) {
            console.error(`[msg.get] No message found for path: ${path}`)
        }

        for (const property in params) {
            msg = msg.replace(`$${property}`, params[property])
        }

        return msg
    },
    section_name: {
        bible: "Poklady",
        bible_full: "Poklady z božího slova",
        tasks: "Kazatelská služba",
        tasks_full: "Kazatelská služba",
        life: "Život křesťana",
        life_full: "Život křesťana",
        talk: "Přednáška",
        talk_full: "Přednáška",
        watchtower: "Strážná věž",
        watchtower_full: "Strážná věž",
        services: "Služby",
        services_full: "Služby",
    },
    task_name: {
        song: "Píseň",
        prayer: "Modlitba",
        moderator: "Předsedající",
        talk_week: "Proslov",
        gems: "Hledání duchovních drahokamů",
        class_moderator: "Vedoucí třídy",
        reading: "Čtení Bible",
        video: "Rozbor",
        task: "Úkol - scénka",
        talk_task: "Úkol - proslov",
        partner: "Partner",
        program: "Program ze života křesťana",
        study_moderator: "Sborové studium Bible",
        study_reader: "Čtenář",
        intro: "Úvod a modlitba",
        talk_end: "Přednáška",
        watchtower_moderator: "Strážná věž",
        watchtower_reader: "Čtenář",
        sound: "Zvuk",
        stage: "Pódium",
        microfon: "Mikrofony",
        stevard: "Pořadatel",
        cleaning: "Úklid",
        ministry: "Schůzka před službou",
        special: "Mimořádný program",
    },
    adminRole: {
        section: {
            meeting: "Shromáždění",
            users: "Zvěstovatelé",
            cong: "Sbor",
            other: "Další",
        },
        meeting_create_delete: "Den shromáždění",
        meeting_create_delete_desc: "Tvorba a smazání celého shromáždění + úprava nadpisu a poznámky.",
        meeting_edit_gems: "Poklady",
        meeting_edit_gems_desc: "Tvorba a úprava programů v sekci Poklady.",
        meeting_edit_tasks: "Úkoly",
        meeting_edit_tasks_desc: "Tvorba a úprava programů v sekci Kazatelská služba.",
        meeting_edit_life: "Život křesťana",
        meeting_edit_life_desc: "Tvorba a úprava programů v sekci Život křesťana.",
        meeting_edit_talk: "Přednášky",
        meeting_edit_talk_desc: "Tvorba a úprava programů v sekci Přednáška.",
        meeting_edit_watchtower: "Strážná věž",
        meeting_edit_watchtower_desc: "Tvorba a úprava programů v sekci Strážná věž.",
        meeting_edit_services: "Služby",
        meeting_edit_services_desc: "Úprava programů v sekci Služby - zvuk, pořadatelská, úklidy.",

        users_edit_details: "Uživatelské detaily",
        users_edit_details_desc: "Úprava detailů uživatele (tel., email, adresa,jméno, služební skupina, sbor hosta) a tvorba nových uživatelů.",
        users_edit_details_warn: "<span style='color: red; font-weight: bold'>POZOR!</span> V kombinaci s 'Resetem hesla' se uživatel, který toho zneužije, bude moct dostat na účet kohokoli ve sboru!",
        users_edit_rights: "Role a práva",
        users_edit_rights_desc: "Úprava výsad (služební pomocník, starší...) a práv zvěstovatele (modlitba, čtení...).",
        users_edit_admin: "Admin role",
        users_edit_admin_desc: "Úprava admin práv (které zrovna upravuješ ty) uživatele.",
        users_edit_admin_warn: "<span style='color: red; font-weight: bold'>POZOR!</span> Toto je nejvyšší přístup. Uživatel, který toho zneužije, se bude moct dostat na účet kohokoli ve sboru!",
        users_edit_password: "Hesla",
        users_edit_password_desc: "Odeslání registračního emailu, kde si uživatel může nastavit heslo.",
        users_edit_password_warn: "<span style='color: red; font-weight: bold'>POZOR!</span> V kombinaci s 'Uživatelské detaily' se uživatel, který toho zneužije, bude moct dostat na účet kohokoli ve sboru!",

        cong_view: "Nastavení sboru",
        cong_view_desc: "Zobrazení a úprava nastavení sboru.",

        ministry: "Schůzky",
        ministry_desc: "Tvorba a úprava schůzek před službou.",

        carts: "Služba na veřejnosti",
        carts_desc: "Tvorba a úprava šablon a směn pro veřejnou službu.",

        generate_meeting_excel: "Generování rozpisů",
        generate_meeting_excel_desc: "Generování rozpisu shromáždění do excelové tabulky.",

        special: "Mimořádný program",
    },
    role: {
        man: "Muž",
        brother: "Bratr",
        publisher: "Zvěstovatel",
        baptised: "Pokřtěný",
        servant: "Služební pomocník",
        elder: "Starší",
        secretary: "Tajemník",
        admin: "Admin",
        pioneer: "Pravidelný průkopník",
        anointed: "Pomazaný",
        missionary: "Misionář",
        special_pioneer: "Zvláštní průkopník",
        inactive: "Nečinný",
        short: {
            man: "Muž",
            brother: "Bratr",
            publisher: "Zvěstovatel",
            baptised: "Pokřtěný",
            servant: "Služ. pomocník",
            elder: "Starší",
            secretary: "Tajemník",
            admin: "Admin",
            pioneer: "Prav. průkopník",
            anointed: "Pomazaný",
            missionary: "Misionář",
            special_pioneer: "Zv. průkopník",
            inactive: "Nečinný",
        },
    },

    meeting: {
      task: {
        nobody: "Tento typ programu nemá přiřazen žádný uživatel."
      },
    },

    board: {
        userCreated: "Uživatel $userName vytvořen.",
    },

    password_conditions: "Heslo musí obsahovat následující:<br>• délka 8 - 32 znaků,<br>• alespoň jedno velké a malé písmeno,<br>• alespoň jedno číslo,<br> • alespoň jeden symbol.",

    login: {
        login: {
            fill_password: "Pro přihlášení vyplň heslo.",
            invalid_password: "Tohle heslo to určitě nebude. ",
            wrong_password: "Špatná kombinace emailu a hesla.",
        },
        reset: "Pokud existuje účet se zadanou adresou, posíláme na něj email.",
    },

    ministry: {
        item: {
            create: "Vytvořit"
        },
        new: "Nová SPS",
        export: "Export",
    },

    profile: {
        changePassword: {
            success: "Heslo úspěšně změněno.",
            originalPasswordEmpty: "Musíš vyplnit původní heslo.",
            newPasswordInvalid: "Nevalidní nové heslo.",
        },
    },

    user: {
        tempPassword: {
            generated: "Email s odkazem odeslán.",
            existing: "Odkaz už existuje a $user.firstName by ho měl(a) mít na mailu.",
        },
        delete: {
          ok: "Uživatel smazán."
        },
        gehena: {
          ok: "Uživatel úplně odstraněn."
        },
        renew: {
          ok: "Uživatel úspěšně obnoven. Nyní mu můžete znovu nastavit jméno, příjmení, atd..."
        },
        programRight: {
            ministry: "Vedení SPS",
            special: "Mimořádný program",
            prayer: "Modlitba",
            moderator: "Předsedající v týdnu",
            talk_week: "Poklady",
            gems: "Drahokamy",
            class_moderator: "Vedoucí třídy",
            reading: "Úkol - čtení Bible",
            video: "Rozbor videa do služby",
            task: "Úkoly",
            talk_task: "Proslov - úkol",
            program: "Život křesťana",
            study_moderator: "Studium Bible",
            study_reader: "Studium - čtenář",
            intro: "Předsedající o víkendu",
            talk_end: "Přednáška",
            watchtower_moderator: "Strážná věž",
            watchtower_reader: "Strážná věž - čtenář",
            sound: "Zvuk",
            stage: "Pódium",
            microfon: "Mikrofony",
            stevard: "Pořadatel",
            public_min: "Služba na veřejnosti",
        },
    },

    friends: {
        connection: {
            none: "NENASTAVENO",
            requested: "POŽÁDÁNO",
            accepted: "POVOLENO",
            blocked: "ZAMÍTNUTO",
        },
    },

    report: {
        new: "Nový",
        publishers: {
            empty: "Zvěstovatel nemá žádné záznamy ze služby",
        },
    },

    error: {
        badRequest: {
            general: "Chybná vstupní data. Pravděpodobně se jedná o chybu v aplikaci. Kontaktujte administrátora.",
            // obecne
            id: { name: "id", rod: "it"},
            time: { name: "čas", rod: "m"},
            date: { name: "datum", rod: "it"},
            dateFrom: { name: "datum začátku", rod: "it"},
            month: { name: "měsíc", rod: "m"},
            // sbor
            numberOfClasses: "Počet tříd sboru musí být mezi 1 a 4.",
            name: { name: "jméno sboru", rod: "it"},
            midweekDay: { name: "den shromáždění", rod: "m"},
            serviceList: { name: "seznam programů", rod: "m"},
            userId: { name: "id uživatele", rod: "it"},
            excludeClasses: "Zaslané třídy neodpovídají maximálnímu počtu tříd ve sboru.",
            // uzivatel
            firstName: { name: "křestní jméno", rod: "it"},
            lastName: { name: "příjmení", rod: "it"},
            phone: { name: "telefon", rod: "m"},
            email: { name: "email", rod: "m"},
            program: { name: "program", rod: "m"},
            user: { name: "uživatel", rod: "m"},
            programRightList: { name: "seznam programů", rod: "m"},
            // ministry
            ministryId: { name: "id schůzky", rod: "it"},
            until: { invalid: {
                maxYear: "Opakování je možné vytvořit max. na rok dopředu.",
                firstOccurrenceAfterUntil: "Sérii, která má začít ještě před prvním opakováním, nelze vytvořit.",
            } },
            place: { length: "Délka Místa musí být nižší, než 40 znaků."},
            desc: { length: "Délka Poznámky musí být nižší, než 40 znaků."},
            serieDesc: { length: "Délka popisku série musí být nižší, než 400 znaků."},
            // profil
            newPassword: { name: "nové heslo", rod: "it"},
            password: { name: "heslo", rod: "it"},
            GroupDto: {
                name: { name: "jméno skupinky", rod: "it"},
            },
            CreateMultipleUserDto: {
                line: { name: "řádka", rod: "f"},
            },
        },
        concurrentAccess: {
            general: `Práce s nevalidními daty. ${common.contactAdmin}`,
            email: {
                duplicate: "Email je již používaný jiným uživatelem. Zadejte prosím jiný."
            },
            user: {
                notFound: "Uživatel nenalezen.",
                cantDeleteYourself: "Není dovoleno smazat sám sebe.",
            },
            profile: {
                tempPassword: {
                    emailEmpty: "Uživatel musí mít vyplněnou emailovou adresu."
                }
            },
            task: {
                notFound: "Přidružený úkol nebyl nalezen.",
                hasAdmin: {
                    invalidOrder: "Snaha ověřit úkol podle nevalidního atributu 'order'. " +
                        "Pravděpodobně se jedná o chybu v systému.",
                },
            },
        },
        invalidAccess: {
            general: `Nevalidní přístup. ${common.contactAdmin}`,
            rights: `Pro akci nemáte dostatečná práva. ${common.contactAdmin}`,
            profile: {
                changePassword: {
                    originalPasswordBad: "Původní heslo nesouhlasí.",
                }
            },
            user: {
                deleted: "Není možné pracovat se smazaným uživatelem.",
                badCong: "Uživatel musí být z tvého sboru."
            },
            ministry: {
                badCong: "Skupina schůzky musí být z tvého sboru.",
            },
            report: {
                afterDate: "Takto starý záznam ze služby již není možné vytvořit/upravovat."
            },
            fastLogin: {
                no: "Odkaz z nějakého důvodu není platný.",
                attemptsMaxedOut: "Překročen maximální počet pokusů.",
                expired: "Odkaz / QR kód už vypršel.",
            },
        }
    }
}